<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-row class="align-self-start">
      <v-col cols="12" lg="12">
        <BaseCard heading="Lista wallets master">
            <!-- ----------------------------------------------------------------------------- -->
            <!-- TableSimpleFixHeader -->
            <!-- ----------------------------------------------------------------------------- -->
            <!-- <v-list-item-subtitle class="text-wrap">
            Use the <code>fixed-header</code> prop together with the <code>height</code> prop to fix the header to the top of the table.
            </v-list-item-subtitle> -->
            <div class="mt-0">       
            <v-data-table
                :headers="headers"
                :items="listTipowallets"
                item-key="id"
                sort-by="fecha"
                :sort-desc="true"
                :search="search"
                :items-per-page="10"
                :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right'
                }"
                :loading="loading"
                loading-text="Cargando... Un momento por favor"
            >
                <!-- This template looks for headers with formatters and executes them -->
                <!-- <template
                v-for="header in headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
                >
                {{ header.formatter(value) }}
                </template> -->                
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar"
                            single-line
                            hide-details
                            class="pb-5"
                        ></v-text-field> 
                        <v-spacer></v-spacer>
                        <v-btn
                        :loading="loading3"
                        :disabled="loading3"
                        color="info"
                        class="ma-2 white--text"
                        @click="loader = 'loading3'"
                        >
                        Exportar
                        <v-icon
                            right
                            dark
                        >
                            mdi-cloud-download
                        </v-icon>
                        </v-btn>
                        <v-btn
                        :loading="loading3"
                        :disabled="loading3"
                        color="info"
                        class="ma-2 white--text"
                        @click="loader = 'loading3'"
                        >
                        Importar
                        <v-icon
                            right
                            dark
                        >
                            mdi-cloud-upload
                        </v-icon>
                        </v-btn>                         
                        <v-btn
                            class="mb-1"
                            fab
                            dark
                            small
                            color="green"
                            title="Agregar"
                            @click="agregar"
                        >
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn> 
                        <v-btn
                            class="mb-1"
                            fab
                            dark
                            small
                            color="success"
                            title="Refrescar"
                            @click="getWalletsmaster"
                        >
                            <v-icon dark>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
                <template  v-slot:item.logo="{ item }">
                    <v-img
                        :lazy-src= item.logo
                        max-height="50"
                        max-width="50"
                        :src=item.logo
                    >
                    </v-img>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-chip
                        :color="getColor(item.status)"
                        dark
                    >
                        {{ item.status }}
                    </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                     <v-toolbar flat>
                        <v-btn
                            class="mini-boton"
                            @click="editar(item.id)"
                            fab
                            dark
                            small
                            color="warning"
                            title="Editar"
                        >
                            <v-icon dark >mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            class="ml-2 mini-boton"
                            @click="setEliminar(item.id)"
                            fab
                            dark
                            small
                            color="error"
                            title="Eliminar"
                        >
                            <v-icon dark >mdi-delete</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>
            </v-data-table>
            </div>
            <v-dialog v-model="dialogEliminar" persistent max-width="290" >
            <v-card>
                <v-card-title class="text-h5">
                {{titleEliminar}}?
                </v-card-title>
                <v-card-text>
                Confirma que desea eliminar la walletmaster seleccionada?
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" text @click="dialogEliminar = false" >
                    No
                </v-btn>
                <v-btn color="primary" text @click="eliminar" >
                    Si
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="dialogConstruccion" persistent max-width="290" >
            <v-card>
                <v-card-title class="text-h5">
                En construcción
                </v-card-title>
                <v-card-text>
                En construcción
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="setAceptar" >
                    Aceptar
                </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </BaseCard>
      </v-col>
    </v-row>
    <sesionExpiredDialog
      :dialog.sync="dialogSesion"
      persistent
      transition="dialog-bottom-transition"
      max-width="320"
    >
    </sesionExpiredDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import VsToast from '@vuesimple/vs-toast'
import sesionExpiredDialog from '@/components/mainComponents/SessionExpiredDialog.vue'

export default {
    name: "Tipowalletsusers",

    data: () => ({
        id_wallet: null,
        titleEliminar: 'Eliminar Wallet',
        dialogEliminar: false,
        dialogConstruccion: false,
        id_tipo_waller_user: null,
        usrToken: '',
        usrData: {},
        loading: true,
        search: '',
        dialogSesion: false,
        listTipowallets: [],
        headers: [
            {
            text: 'Codigo',
            align: 'start',
            sortable: false,
            value: 'id',
            },
            { text: 'Wallet', value: 'wallet' },
            { text: 'Descripción', value: 'descripcion' },
            { text: 'Formato', value: 'formato' },
            { text: 'Logo', value: 'logo' },
            { text: 'status', value: 'status' },
            { text: 'Actions', value: 'actions', sortable: false, width: 125, align: 'center'},
        ],
    }),
  
    components: {
      sesionExpiredDialog
    },
  
    methods: {
        getColor (status) {
            if (status == 'Disponible') return 'green'
            else return 'Red'
        },
        agregar(){
            this.$router.push('/pages/administracion/frmwalletsmaster'); 
        },
        editar(id){
            this.id_tipo_waller_user = id
            this.$router.push('/pages/administracion/frmwalletsmaster/'+id); 
        },
        setEliminar(id){
            this.id_wallet = id
            this.dialogEliminar = true
        },
        eliminar() {
            console.log("eliminar id:", this.id_wallet);
            this.dialogEliminar = false
            axios({
                method: 'DELETE',
                url: `${process.env.VUE_APP_API_URL}/api/walletmaster/${this.id_wallet}`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let resp = response.data
                //console.log('Retiros...', respAprobar);
                if (!resp.success){
                    VsToast.show({
                        title: this.titleEliminar,
                        message: resp.msg,
                        variant: 'warning',
                        timeout: 2000,
                        type: "alert"
                    });
                    console.error('Retiros Error: ', resp)
                }
                else {
                    VsToast.show({
                        title: this.titleEliminar,
                        message: 'Wallet eliminada correctamente.',
                        variant: 'success',
                        timeout: 2000,
                        type: "alert"
                    });
                    this.getWalletsmaster()
                }
            })
            .catch(error => {
                console.error('Wallets Error: ', error)
                VsToast.show({
                    title: this.titleEliminar,
                    message: error.message,
                    variant: 'error',
                    timeout: 2000,
                    type: "alert"
                });
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialogSesion = true
                }
            })
        },
        setAceptar() {
            this.dialogConstruccion = false
        },
        getWalletsmaster() {
            this.loading = true
            //Obtener los datos de las respTipowalletsusers
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_API_URL}/api/walletmaster`,
                headers: { 
                    "Authorization": process.env.VUE_APP_TOKEN_TYPE + ' ' +this.usrToken ,
                    //"Accept": "application/json"
                }
            })
            .then(async response => {
                let respTipowalletsusers = response.data
                //console.log('Transacciones...', respTransaccion);
                if (!respTipowalletsusers.success){
                    console.error('Tipo wallet Error: ', respTipowalletsusers)
                }
                else {
                    this.listTipowallets = respTipowalletsusers.data.map((data) => {
                        //console.log(trans.valor, trans.valor.toLocaleString("es-VE", {style:"currency", currency:"USD"}))
                        return {
                            id: data.id,
                            wallet: data.wallet,
                            descripcion: data.tx_descripcion,
                            status: data.tx_status,
                            created: data.created_at,
                            updated: data.created_at,
                            formato: data.tipo_wallet_master.nb_tipo_wallet,
                            logo: data.tipo_wallet_master.tx_img_logo
                        }
                    })
                }
                
                this.loading = false
            })
            .catch(error => {
                console.error('Tipo wallets Error: ', error)
                this.loading = false;
                //let message = !error.response ? error.message : error.response.data.msg;
                if(error.response && error.response.statusText == "Unauthorized"){
                    this.dialogSesion = true
                }
            })
        }
    },
  
  async created () {
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    this.usrToken = localStorage.getItem("usrToken")
    this.getWalletsmaster()
  }
};
</script>