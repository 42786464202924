<template>
    <v-dialog
      :value="sDialog"
      persistent
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title class="text-h5">
          Salir de Ticktack
        </v-card-title>

        <v-card-text>
          Su sesión de usuario ha expirado.
          <br />
          Serás redirigido al login.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="sesionExpired"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'sesionExpiredDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '290'
    },
  },
  data: () => ({
    sDialog: false
  }),

  watch: {
    dialog (newValue, oldValue) {
        console.log('sesion dialog: ', newValue, oldValue)
      this.sDialog = newValue // Update when the prop changes
    }
  },

    mutations: {
        SET_DIALOG (props, payload) {
            props.dialog = payload
        },
    },

  methods: {
        sesionExpired() {
            localStorage.removeItem("user")
            localStorage.removeItem("usrToken")
            location.href= process.env.VUE_APP_WEB_URL+'#login'
        }
  }

}
</script>

<style>

</style>